// eslint-disable-next-line no-redeclare,no-unused-vars
function getFieldSettings(param, key) {
  var settings;
  if (param.settingsNew) settings = param.settingsNew;
  else if (param.settings && key)
    settings = $.extend(true, {}, param.settings[key]);
  else if (param.object && key && getObjectValue(fields, [param.object, key]))
    settings = $.extend(true, {}, fields[param.object][key]);
  // else if (param && !param.object) settings = $.extend(true, {}, param);
  else if (param && !param.object) settings = Object.assign({}, param);
  else return {};

  // field index
  if (param.index) {
    var field = 'Field' + param.index;
    $.each(settings, function (option, value) {
      if (str_ends_with(option, field) && !str_starts_with(option, field))
        settings[option.slice(0, option.length - field.length)] = value;
    });
  }

  // roll
  if (!param.role) param.role = role;
  param.role = ucfirst(param.role);
  var length = role.length;
  $.each(settings, function (option, value) {
    if (option.slice(-length) == param.role)
      settings[option.slice(0, option.length - length)] = value;
  });

  // object sub
  if (param.objectSub) {
    var objectSub = ucfirst(param.objectSub);
    length = objectSub.length;
    $.each(settings, function (option, value) {
      if (option.slice(-length) == objectSub)
        settings[option.slice(0, option.length - length)] = value;
    });
  }

  // sidebar
  if (param.sidebar)
    $.each(settings, function (option, value) {
      if (str_ends_with(option, 'Sidebar'))
        settings[option.slice(0, -7)] = value;
    });

  // lang
  if (user.language && user.language != 'de') {
    var lang = ucfirst(user.language);
    length = lang.length;
    $.each(settings, function (option, value) {
      if (option.slice(-length) == lang)
        settings[option.slice(0, option.length - length)] = value;
    });
  }

  // table
  if (param.table)
    $.each(settings, function (option, value) {
      if (option.slice(-5) == 'Table')
        settings[option.slice(0, option.length - 5)] = value;
    });
  $.each(settings, function (option, value) {
    if (
      option.slice(-3) === 'Add' &&
      isArray(value) &&
      isArray(settings[option.slice(0, option.length - 3)])
    ) {
      var opt = option.slice(0, option.length - 3);
      settings[opt] = arrayMerge(settings[opt], value);
      settings[opt] = arrayUnique(settings[opt]);
    }
  });

  // object ref
  if (
    settings.reference &&
    strpos(settings.reference, 'Object') > 0 &&
    getObjectValue(data, [param.object, param.id, settings.reference])
  ) {
    settings.reference = getObjectValue(data, [
      param.object,
      param.id,
      settings.reference,
    ]);
    settings.objectSub = getObjectValue(data, [
      param.object,
      param.id,
      settings.objectSub,
    ]);
  }

  // if (!settings.label && test) settings.label = key;
  return settings;
}
